import React, {useEffect, useState} from "react";
import {ethers} from "ethers";
import { useWallet, UseWalletProvider } from 'use-wallet';
import exchangeAbi from "../../abis/exchange.json";
import 'react-toastify/dist/ReactToastify.css';
import "./style.css";
import 'react-tabs/style/react-tabs.css';
import ProfileInfo from '../../layout/ProfileInfo'
import ProfileTabs from '../../layout/ProfileTabs'
import {SkeletonTheme} from "react-loading-skeleton";
import newExchangeAbi from "../../abis/newExchange.json";

const opts = {
    preflightCommitment: "confirmed"
}

const List = ({ name }) => {
    let providerBsc;
    let exchange;
    const [nftData, setnftData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [artistPost, setArtistPost] = useState();
    const [inCircle, setInCircle] = useState(false);
    const { account, provider, balance } = useWallet();
    let myNFTs = [];
    const wallet = useWallet();



    useEffect(async () => {
        setLoading(true);

        let providerAvax = await new ethers.providers.JsonRpcProvider('https://api.avax-test.network/ext/bc/C/rpc');
        let buyExchange = await new ethers.Contract('0x7E4BB05E084EB64D9cF55Fd4094688e20dCEeE7e', newExchangeAbi.abi, providerAvax);

        if (account != null) {
            let ownedNFT = await buyExchange.getUserOwnedNFTList(account);
            for (let i = 0; i < ownedNFT.length; i++) {
                myNFTs.push(ownedNFT[i].toString());
            }
        }
        try {
            const axios = require('axios');
            const serverResponse = await axios.get(`https://releap-next.vercel.app/api/artist/${name}`);
            setArtistPost(serverResponse.data);
        } catch(e) {
            console.log(e);
        }
        providerBsc = await new ethers.providers.JsonRpcProvider('https://rpc.api.moonriver.moonbeam.network');
        exchange = await new ethers.Contract('0x25B928897C8cFAa4242d5476251d73FC3d1f0bb9', exchangeAbi.abi, providerBsc);
        const response = await exchange.getAllNFTsforArtist(name);
        await setnftData(response);
        for (let i = 0; i < response[0].length; i++){
            console.log(myNFTs.includes(response[0][i].id.toString()))
            if (myNFTs.includes(response[0][i].id.toString())){
                setInCircle(true);
            }
        }
        setLoading(false);
    }, [wallet])

  return (
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className="artist-banner" style={{width: '100%',
              minHeight: '100vh',
              background: loading ? 'linear-gradient(180deg, rgba(67, 67, 67, 0.1) 300px, #141414 388px)' : `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 215px, #141414 358px) no-repeat, url('${nftData[1].coverImage}') top center no-repeat`,
             }}>
              <main className="profile-content">
                  <ProfileInfo artist={artistPost} releases={nftData} loading={loading} inCircle={inCircle}/>
                  <ProfileTabs artist={nftData} posts={artistPost} inCircle={inCircle} loading={loading}/>
              </main>
          </div>
      </SkeletonTheme>

  );
};

export default List;
