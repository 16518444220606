import React from 'react'
import './style.scss'

const LayoutInfo = ({ info }) => {
  if (!info) {
    return (
      <div className="app-null-layout-info" />  
    )
  }
  return (
    <div className="app-layout-info">
      {info}
    </div>
  )
}

export default LayoutInfo
