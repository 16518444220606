import React from 'react'
import PostCard from '../../components/PostCard'
import LockedPostCard from '../../components/LockedPostCard'
import './style.scss'
import PostCardLoading from "../../components/PostCard/index-loading";

const PostPageLayout = (props) => {
  return (
      <div className="app-post-page-layout">
          {props.loading ? <PostCardLoading type="full"/>  : (props.inCircle ? props.posts.posts.map((item, index) => (
            <PostCard artist={props.posts.artist} data={item} key={index} type="full"/>
          )) :  <LockedPostCard data={props.posts.artist} type="full" />)
        }
      </div>
    )
}

export default PostPageLayout
