import Album from "../../layout/Album";
import MusicInfo from "../../layout/MusicInfo";
import Details from "../../layout/Details";
import Buy from "../../layout/Buy";
import CreatorCard from "../../components/CreatorCard";
import "../../screens/Music/style.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ethers } from "ethers";
import exchangeAbi from "../../abis/exchange.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import idl from "../../abis/anchor.json";
import { useWallet, UseWalletProvider } from 'use-wallet';
import {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const anchor = require("@project-serum/anchor");
/* create an account  */
const opts = {
  preflightCommitment: "confirmed",
};

let providerBsc;
let polygonRPC;
let exchange;
let audioInstance;
let length;
let localPlaying = false;
let buyExchange;

const Item = ({ id, props }) => {
  const { account, provider, balance } = useWallet();
  const [enoughFunds, setenoughFunds] = useState(true);
  const [amountava, setamountava] = useState(0);
  const [nftData, setnftData] = useState();
  const [loading, setLoading] = useState(true);
  const [nftPrice, setNftPrice] = useState(0);
  const [buttonSpin, setButtonSpin] = useState(false);
  const wallet = useWallet();
  const [volume, setVolume] = useState(0.5);
  const [playing, setPlaying] = useState(false);
  const [tracktime, setTracktime] = useState(0);


  useEffect(async () => {
    setLoading(true); //change
    providerBsc = await new ethers.providers.JsonRpcProvider(
      "https://rpc.api.moonriver.moonbeam.network"
    );
    exchange = await new ethers.Contract(
      "0x25B928897C8cFAa4242d5476251d73FC3d1f0bb9",
      exchangeAbi.abi,
      providerBsc
    );
    buyExchange = await new ethers.Contract(
        "0x7E4BB05E084EB64D9cF55Fd4094688e20dCEeE7e",  //0xD2E6452A1E261a8b33Cf1C273EbDbe886AE5Bdd8
        exchangeAbi.abi,
        polygonRPC
    );
    const response = await exchange.getNftinfo(id);
    setnftData(response);
    const priceResponse = await axios.get(
      "https://api.binance.com/api/v3/ticker/price?symbol=AVAXUSDT"
    );
    setNftPrice(priceResponse.data.price * response[0].price);
    setamountava(response[0].amountAvailable.toString());
    if (account != null) {
      if (balance >= ethers.BigNumber.from(response[0].price).toBigInt()) {
        await setenoughFunds(true);
        console.log("ENOUGH FUNDS");
      } else {
        if (balance > -1) {
          await setenoughFunds(false);
          console.log("NOT ENOUGH");
        }
      }
    }
    setLoading(false);
  }, [provider]);

  useEffect(async () => {
    if (account != null && nftData != null){
      // await getUserNFTs();
      if (balance >= ethers.BigNumber.from(nftData[0].price).toBigInt()){
        console.log('ENOUGH FUNDS');
        await setenoughFunds(true);
      } else {
        if (balance > -1){
          await setenoughFunds(false);
          console.log('NOT ENOUGH');
        }
      }
    }
  }, [provider, account, balance])

  async function buyNft() {
    if (account == null){
      await notify();
    } else {
      console.log('Pressed Buy');
      if (window.ethereum != null) {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        if ((await provider.getNetwork()).chainId != 43113) {
          notify();
        } else {
          if (balance >= ethers.BigNumber.from(nftData[0].price).toBigInt()) {
            await setenoughFunds(true);
            console.log('enough');
          } else {
            if (balance > -1) {
              await setenoughFunds(false);
            }
            console.log('not enough');
          }
          buyExchange = await new ethers.Contract('0x7E4BB05E084EB64D9cF55Fd4094688e20dCEeE7e', exchangeAbi.abi, signer);
          try {
            setButtonSpin(true);
            // console.log('LOADING')
            const tx = await buyExchange.buyNFT(nftData[0].id, {value: ethers.BigNumber.from(nftData[0].price)});
            await tx.wait();
            await notifyBought();
            setamountava(amountava - 1);
          } catch (e) {
            console.log(e);
          }
          setButtonSpin(false);
        }
      } else {
        await notify();
      }
    }
  }

  const notify = () =>
    toast.dark("Please connect to the Avalanche Testnet Network!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      style: { marginBottom: 70 },
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyBought = () =>
    toast.dark("You have bought an NFT!", {
      position: "bottom-right",
      autoClose: 5000,
      style: { marginBottom: 70 },
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const options = {
    // audio lists model
    audioLists: [
      {
        name: nftData != null ? nftData[0].name : "",
        singer: nftData != null ? nftData[0].artistName : "",
        cover: nftData != null ? nftData[0].imageUrl : "",
        musicSrc:
          nftData != null
            ? (id < 3
              ? `${nftData[0].streamUrl}`
              : `/${id}.mp3`)
            : "",
      },
    ],

    // default play index of the audio player  [type `number` default `0`]
    defaultPlayIndex: 0,

    // if you want dynamic change current play audio you can change it [type `number` default `0`]
    // playIndex: 0,

    // color of the music player theme    [ type: 'light' | 'dark' | 'auto'  default `dark` ]
    theme: "dark",

    bounds: "body",

    /**
     * Don't interrupt current playing state when audio list updated
     * audioLists eg. (A) is current playing...
     * [A,B] => [A,C,B]
     * [A,B] => [A,B,C]
     *
     * if (A) not in updated audio lists
     * [A,B] => [C]
     * (C) is playing
     */
    // [type `boolean`, default `false`]
    quietUpdate: false,

    // Replace a new playlist with the first loaded playlist
    // instead of adding it at the end of it.
    // [type `boolean`, default `false`]
    clearPriorAudioLists: false,

    // Play your new play list right after your new play list is loaded turn false.
    // [type `boolean`, default `false`]
    autoPlayInitLoadPlayList: false,

    // Whether to load audio immediately after the page loads.  [type `Boolean | String`, default `false`]
    // "auto|metadata|none" "true| false"
    preload: false,

    // Whether the player's background displays frosted glass effect  [type `Boolean`, default `false`]
    glassBg: false,

    // The next time you access the player, do you keep the last state  [type `Boolean` default `false`]
    remember: false,

    // The Audio Can be deleted  [type `Boolean`, default `true`]
    remove: false,

    // audio controller initial position    [ type `Object` default '{top:0,left:0}' ]
    defaultPosition: {
      right: 100,
      bottom: 120,
    },

    // if you want dynamic change current play mode you can change it
    // [type`order | orderLoop | singleLoop | shufflePlay`, default `order`]
    // playMode: 'order',
    defaultPlayMode: "order",

    // audio mode        mini | full          [type `String`  default `mini`]
    mode: "full",

    /**
     * [ type `Boolean` default 'false' ]
     * The default audioPlay handle function will be played again after each pause, If you only want to trigger it once, you can set 'true'
     */
    once: false,

    // Whether the audio is played after loading is completed. [type `Boolean` default 'true']
    autoPlay: false,

    // Whether you can switch between two modes, full => mini  or mini => full   [type 'Boolean' default 'true']
    toggleMode: false,

    // audio cover is show of the "mini" mode [type `Boolean` default 'true']
    showMiniModeCover: false,

    // audio playing progress is show of the "mini"  mode
    showMiniProcessBar: false,

    // audio controller is can be drag of the "mini" mode     [type `Boolean` default `true`]
    drag: false,

    // drag the audio progress bar [type `Boolean` default `true`]
    seeked: true,

    // Display chrome media session.  [type `Boolean` default `false`]
    showMediaSession: false,

    // Displays the audio load progress bar.  [type `Boolean` default `true`]
    showProgressLoadBar: true,

    // play button display of the audio player panel   [type `Boolean` default `true`]
    showPlay: true,

    // reload button display of the audio player panel   [type `Boolean` default `true`]
    showReload: false,

    // download button display of the audio player panel   [type `Boolean` default `true`]
    showDownload: false,

    // loop button display of the audio player panel   [type `Boolean` default `true`]
    showPlayMode: false,

    // theme toggle switch  display of the audio player panel   [type `Boolean` default `true`]
    showThemeSwitch: false,

    // lyric display of the audio player panel   [type `Boolean` default `false`]
    showLyric: false,

    // destroy player button display  [type `Boolean` default `false`]
    showDestroy: false,

    // Extensible custom content       [type 'Array' default '-' ]
    extendsContent: null,

    // default volume of the audio player [type `Number` default `1` range `0-1`]
    defaultVolume: 0.5,

    // playModeText show time [type `Number(ms)` default `600`]
    playModeShowTime: 600,

    // Whether to try playing the next audio when the current audio playback fails [type `Boolean` default `true`]
    loadAudioErrorPlayNext: true,

    // Auto hide the cover photo if no cover photo is available [type `Boolean` default `false`]
    autoHiddenCover: false,

    // Play and pause audio through blank space [type `Boolean` default `false`]
    spaceBar: false,

    // international [type `en_US | zh_CN | Object` default `en_US`]
    // Enable responsive player, auto toggle desktop and mobile [type `Boolean` default `true`]
    responsive: false,

    /**
     * Custom mobile media query string, eg use the mobile version UI on iPad.
     * https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries
     * [type `String` default '(max-width: 768px) and (orientation : portrait)']
     */
    mobileMediaQuery: "(max-width: 1024px)",

    // Audio volume with fade in and fade out [type `{ fadeIn: number, fadeOut: number }` default `{ fadeIn: 0, fadeOut: 0 }`]
    volumeFade: {
      fadeIn: 1000,
      fadeOut: 1000,
    },
    /**
         * Restarts the current track when trying to play previous song, if the current time of the song is more than 1 second
         Otherwise, plays the previous song in the list
         [type `Boolean` default `false`]
         */
    restartCurrentOnPrev: false,

    // https://github.com/SortableJS/Sortable#options
    sortableOptions: {},

    // Music is downloaded handle
    onAudioDownload(audioInfo) {
      console.log("audio download", audioInfo);
    },

    // audio play handle
    onAudioPlay(audioInfo) {
      console.log("audio playing", audioInfo);
      setTracktime(audioInfo);
      length = audioInfo.duration;
      setPlaying(true);
      localPlaying = true;
    },

    // audio pause handle
    onAudioPause(audioInfo) {
      console.log("audio pause", audioInfo);
      setTracktime(audioInfo);
      setPlaying(false);
      localPlaying = false;
    },

    // When the user has moved/jumped to a new location in audio
    onAudioSeeked(audioInfo) {
      console.log("audio seeked", audioInfo);
      setTracktime(audioInfo);
    },

    // When the volume has changed  min = 0.0  max = 1.0
    onAudioVolumeChange(currentVolume) {
      console.log("audio volume change", currentVolume);
      setVolume(currentVolume);
    },

    // The single song is ended handle
    onAudioEnded(currentPlayId, audioLists, audioInfo) {
      console.log("audio ended", currentPlayId, audioLists, audioInfo);
    },

    // audio load abort
    onAudioAbort(currentPlayId, audioLists, audioInfo) {
      console.log("audio abort", currentPlayId, audioLists, audioInfo);
    },

    // audio play progress handle
    onAudioProgress(audioInfo) {
      // console.log('audio progress', audioInfo)
    },

    // audio reload handle
    onAudioReload(audioInfo) {
      console.log("audio reload:", audioInfo);
    },

    // audio load failed error handle
    onAudioError(errMsg, currentPlayId, audioLists, audioInfo) {
      console.error(
        "audio error",
        errMsg,
        currentPlayId,
        audioLists,
        audioInfo
      );
    },

    onAudioListsChange(currentPlayId, audioLists, audioInfo) {
      console.log("audio lists change:", currentPlayId, audioLists, audioInfo);
    },

    onAudioPlayTrackChange(currentPlayId, audioLists, audioInfo) {
      console.log(
        "audio play track change:",
        currentPlayId,
        audioLists,
        audioInfo
      );
    },

    getContainer() {
      return document.body;
    },

    getAudioInstance(audio) {
      audioInstance = audio;
    },
  };

  return (
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className="app-home-page">
      {loading ? "" : <ReactJkMusicPlayer {...options} />}
      <main className="content home-content" style={{ marginBottom: 100 }}>
        <div
          className="app-layouts layout-hero"
          style={{
            background: loading ? '' : `linear-gradient(
                   180deg, rgba(14, 14, 14, 0.2) 0%, rgba(14, 14, 14, 1) 100%), url(${nftData[0].imageUrl})`,
            backgroundSize: "cover",
          }}
        >
          <div className="hero-blur">
            <div className={loading ? "loading-app-music-container" : "app-music-container"}>
              <MusicInfo
                artist={nftData}
                volume={volume}
                playing={playing}
                tracktime={tracktime}
                loading={loading}
                onPlayToggle={() => {
                  if (audioInstance != null) {
                    if (playing) {
                      audioInstance.pause();
                    } else {
                      audioInstance.play();
                    }
                  }
                }}
                onSeek={(time) => {
                  if (length == 0 || length == null) {
                    audioInstance.play();
                  } else {
                    audioInstance.currentTime = time * length;
                  }
                }}
              />
              <Album artist={nftData} loading={loading}/>
            </div>
          </div>
        </div>
        <div className="layout-info-wrapper">
          <div className="layout-info-container">
            <CreatorCard artist={nftData} loading={loading}/>
            <Details artist={nftData} loading={loading} />
            <Buy
              amountAvailable={amountava}
              enoughFunds={enoughFunds}
              buttonSpin={buttonSpin}
              song={nftData}
              loading={loading}
              nftPrice={nftPrice}
              pressedBuy={() => buyNft()}
            />
          </div>
        </div>
      </main>
      </div>
      </SkeletonTheme>
  );
};

export default Item;
