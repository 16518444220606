import React from 'react'
import './style.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TextLabel = ({ align, label, value, type, loading }) => (
  <div className={`app-text-label ${type} ${align}`}>
      {loading ? <Skeleton width={130} count={1} /> : <p className="text-label">{label}</p>}
      {loading ? <Skeleton width={100} count={1} /> : <p className="text-value">{value}</p>}
  </div>
)

export default TextLabel
