import React from "react";

import Button from "../../components/Button";
import Divider from "../../components/Divider";
import "./style.scss";
import Loader from "react-loader-spinner";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const BuyLayout = (props) => {
    const loading = props.loading;
  return (
    <div className="app-buy-layout">
      <p className="price-label">Price</p>
        {loading ? <Skeleton height={30} style={{marginBottom: 10, width: 200}} count={1}/> : <div className="token-supply">
            <div className="copies-available">
                <span>{props.amountAvailable} left</span>
            </div>
            <p className="price-sol">{props.song[0].price.toString() / 1e18} Avax</p>
        </div>}
        {loading ? <Skeleton style={{marginBottom: 10, width: 200}} count={1}/> : <p className="price-usd">{(props.nftPrice / 1e18).toFixed(2)} USD</p>}
      <Button
        enoughFunds={props.enoughFunds}
        onClick={props.enoughFunds ? props.pressedBuy : null}
        title={
          loading ? '-' : (props.buttonSpin ? (
            <Loader type="ThreeDots" color="#000000" height={10} width={68} />
          ) : props.enoughFunds ? (
            "Buy Now"
          ) : (
            "Insufficient funds"
          ))
        }
        type="full"
        size="medium"
      />
      <Divider />
      <p className="price-reep">
        {loading ? <Skeleton style={{ width: 200}} count={1}/> : ((props.nftPrice * 1.2) / 1e19).toFixed(2) + ' REEP'}
      </p>
      <Button
        enoughFunds={props.enoughFunds}
        onClick={props.enoughFunds ? props.pressedBuy : null}
        title={loading ? '-' : (props.enoughFunds ? "Buy with $REEP" : "Insufficient funds")}
        type="full"
        size="medium"
      />{" "}
    </div>
  );
};

export default BuyLayout;
