import React from "react";
import Image from "../../../components/Image";
import styles from "./Card.module.sass";
import solimage from "../../../assets/Avax.png";
import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";

const FeesCard = (props) => {
    const item = props.item;
    return (
        <div className="col-lg-3 col-md-4 col-sm-6">
            <div className={styles.container}>
                <div className={styles.imageWrapper}>
                    <Link to={{pathname: `/music/${item.id}`}} >
                    <Image className={styles.image} src={item.imageUrl} />
                    </Link>
                </div>
                <p className={styles.title}>
                    {item.name}
                </p>
                <div className="flex justify-between items-center">
                    <div className={styles.status}>
                        <p>Fees Accrued</p>
                    </div>
                    <span className={styles.price}>
                        <img style={{height: 12, paddingRight: 5, objectFit: 'cover' }} src={solimage} />
                        {item.royalties / 1e18}
                    </span>
                </div>
                <div className={item.royalties > 0 ? styles.buttonContainer : styles.buttonInactive}>
                    {props.loading ? <button>
                    <Loader
                        type="ThreeDots"
                        color="#000000"
                        height={10}
                        width={30}
                    />
                </button> :
                    item.royalties > 0 ? <button onClick={() => props.redeemRoyalty()}>
                        Claim Fees
                    </button> : <button>
                        Claimed
                    </button>}
                </div>
            </div>
        </div>
    );
};

export default FeesCard;
