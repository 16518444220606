import styled from "styled-components";

export const WaveformContainer = styled.div`
  display: flex;  
  flex-direction: row;  
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 40px;
  background: transparent;
`;

export const Wave = styled.div`
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  margin-right: 24px;
  opacity: 0.8;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 1;
  }
`;
