import React from 'react'
import arrowUpSvg from '../../assets/images/arrow-up.svg';
import './style.scss'

const ShowMoreButton = ({ show, onClick }) => (
  <div className="app-showmore-button" onClick={onClick}>
    <p className="button-text">{show ? 'Show Less' : 'Show More'}</p>
    <img src={arrowUpSvg} className={`arrow-icon ${show ? 'show' : ''}`} alt="arrow-up" />
  </div>
)

export default ShowMoreButton
