import React, { useState } from "react";
import cn from "classnames";
import Select from "react-select";
import MultiSlider, { Progress, Dot } from 'react-multi-bar-slider';
import Icon from "../../../components/Icon";
import styles from "./Filter.module.sass";
import "./reactTags.css";

const Filter = (props) => {
  const options = [
      { value: 'Avax', label: 'Avalanche (Avax)' },
  ];
  const [tags, setTags] = useState(['Rock', 'Electronic', 'Cantopop', 'Indie Pop', 'Rap', 'Korean', 'Psychedelia', 'Hip Hop', 'Jazz']);
  const [selectedTag, setSelectedTag] = useState('');
  const [priceOption, setPriceOption] = useState(options[0]);
  const [price, setPrice] = useState(50);
  const [minPrice, setMinPrice] = useState(0.01);
  const [maxPrice, setMaxPrice] = useState(0.01);

  const handlePriceUnitChange = (value) => {
    setPriceOption(value)
  }

  const handleArtistName = (e) => {
      props.artistName(e.target.value);
  }

  const handlePrice = (value) => {
      props.priceSlider(value)
      setPrice(value)
      setMinPrice(0);
      console.log(value)
      setMaxPrice(parseFloat((value/ 100 ).toFixed(2)));
  }

  const handleMinPrice = (e) => {
      setMinPrice(e.target.value);
      props.minPrice(e.target.value)
  }

  const handleMaxPrice = (e) => {
    setMaxPrice(e.target.value);
    props.maxPrice(e.target.value)
  }

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#202020', borderColor: 'transparent !important', boxShadow: "0" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? "white"
            : isFocused
              ? "#202020"
              : null,
        color: isDisabled
          ? 'black'
          : isSelected
            ? 'black'
            : isFocused
              ? "white"
              : null,
        margin: "10px 0",
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    singleValue: styles => ({ ...styles, color: "white" }),
    menu: styles => ({ ...styles, backgroundColor: "#202020" }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
  };

  return (
    <div className={cn(styles.container)}>
      <p className={cn("mb-1", styles.label)}>
        Genre
      </p>
        <div style={{marginBottom: 10}}></div>
        {tags.map(tag => (
            <button style={{alignItems: 'center',
                fontSize: 13,
                background: '#333',
                color: tag == selectedTag ? 'black' : '#fff',
                paddingLeft: 14,
                paddingRight: 14,
                paddingTop: 6,
                paddingBottom: 6,
                marginRight: 10,
                marginBottom: 10,
                backgroundColor: tag == selectedTag ? '#16EBAE' : '#252525',
                borderRadius: 20}}
                onClick={
                    () =>
                    {
                        tag == selectedTag ?  setSelectedTag('') : setSelectedTag(tag);
                        props.selectedTag(tag == selectedTag ? '' : tag);
                    }
               }
            >
                {tag.toString()}
            </button>
        ))}
        <div className={styles.divider} style={{marginTop: 10}}></div>
        <p className={cn("mt-3 mb-3", styles.label)}>
            artist
        </p>
        <div className={styles.searchWrapper}>
            <input
                type="text"
                name="search"
                placeholder="Search artists"
                required
                onChange={handleArtistName}
            />
            <button>
                <Icon name="search" size="20" />
            </button>
        </div>
        <div className={styles.divider} style={{marginTop: 25}}></div>
        <p className={cn("mt-3 mb-3", styles.label)}>
        pricing
      </p>
      <Select
        value={priceOption}
        onChange={handlePriceUnitChange}
        options={options}
        styles={colourStyles}
      />
      <div className={cn("mt-3 mb-4", styles.priceWrapper)}>
        <input
          className="input"
          type="number"
          step="any"
          placeholder="Min"
          value={minPrice != 0.01 ? minPrice : ''}
          onChange={handleMinPrice}
        />
        <p>to</p>
        <input
          className="input"
          type="number"
          step="any"
          placeholder="Max"
          value={maxPrice != 0.01 ? maxPrice : ''}
          onChange={handleMaxPrice}
        />
      </div>
      <div className={styles.divider}></div>
      <p className={cn("mt-3 mb-3", styles.label)}>
        Max price
      </p>
      <MultiSlider
        height={13}
        slidableZoneSize={25}
        backgroundColor="#16EBAE"
        equalColor="#16EBAE"
        style={{ marginBottom: 20, borderRadius: 0 }}
        onSlide={handlePrice}
        roundedCorners
      >
        <Progress color="green" height={13} progress={price}>
          <Dot className={styles.dot} />
        </Progress>
      </MultiSlider>
        <div className={cn("mt-3 mb-4", styles.sliderPrice)}>
            <div>0 SOL</div>
            <div>1 SOL</div>
        </div>
    </div>
  );
};

export default Filter;
