import React, { useState } from "react";
import Button from "../../components/Button";
import TextLabel from "../../components/TextLabel";
import peopleSvg from "../../assets/images/people.svg";
import badgeSvg from "../../assets/images/check-badge.svg";
import "./style.scss";
import Skeleton  from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProfileInfoLayout = (props) => {
  const [following, setFollowing] = useState(false);
  const loading = props.loading;
  return (
    <>
      <div className="app-profile-info-layout">
        <div className="profile-avatar-container">
          <div className="profile-avatar">
            {loading ? (
              <Skeleton
                style={{
                  borderRadius: 150,
                  height: 150,
                  width: 150,
                  objectFit: "cover",
                }}
                count={1}
              />
            ) : (
              <img
                src={props.artist.artist.imageUrl}
                className="avatar-img"
                alt="avatar"
              />
            )}
          </div>
          {loading ? (
            <Skeleton
              width={180}
              height={36}
              count={1}
              style={{ marginBottom: 10 }}
            />
          ) : (
            <Button
              onClick={() => setFollowing(!following)}
              icon={null}
              className="profile-button"
              title={following ? "Following" : "Follow"}
              size="small"
              type="full"
            />
          )}
          {loading ? (
            <Skeleton width={180} height={36} count={1} />
          ) : (
            <Button
              onClick={() =>
               props.inCircle ? null : alert(`Please buy this artist's NFT to join this circle`)
              }
              icon={peopleSvg}
              className="profile-button"
              title={props.inCircle ? "Joined" : "Join Circle"}
              size="small"
              type="full"
            />
          )}
        </div>
        <div className="profile-details">
          <div className="profile-name-container">
            {loading ? (
              <Skeleton
                width={250}
                height={40}
                style={{ marginRight: 10 }}
                count={1}
              />
            ) : (
              <p className="profile-name">{props.artist.artist.name}</p>
            )}
            {loading ? (
              ""
            ) : (
              <img src={badgeSvg} className="badge-icon" alt="badge" />
            )}
          </div>
          <div className="profile-description-container">
            <div className="profile-reviews">
              <TextLabel
                label={loading ? "-" : props.releases[0].length}
                value="RELEASES"
                type="country"
                size="large"
              />
              <TextLabel
                label={loading ? "-" : 38}
                value="CIRCLERS"
                type="country"
                size="large"
              />
              <TextLabel
                label={
                  loading
                    ? "-"
                    : following
                    ? props.artist.artist.followers + 1
                    : props.artist.artist.followers
                }
                value="FOLLOWERS"
                type="country"
                size="large"
              />
              <TextLabel
                label={loading ? "-" : props.artist.artist.following}
                value="FOLLOWING"
                type="country"
                size="large"
              />
            </div>
            <p className="profile-description">
              {loading ? (
                <Skeleton width={500} count={2} />
              ) : (
                props.artist.artist.description
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileInfoLayout;
