import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Image from "../Image";
import { useWallet, UseWalletProvider } from 'use-wallet';
import {toast, ToastContainer} from "react-toastify";


const Headers = (props) => {
  const [walletAddress, setWalletAddress] = useState("");

    const wallet = useWallet();

    const notify = () => toast.dark(
        'Please install metamask!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    async function connectToBSCTestnet() {
        if (window.ethereum != null) {
            await  window.ethereum.request({
                method: 'wallet_addEthereumChain', params:
                    [{
                        chainId: '0xa869', chainName: 'Avalanche Testnet',
                        nativeCurrency: {name: 'AVAX', symbol: 'Avax', decimals: 18},
                        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'], blockExplorerUrls:
                            ['https://testnet.snowtrace.io/']
                    }]
            });
            await wallet.connect();
        } else {
            await notify();
        }
    }

  return (
    <header className={styles.header}>
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover/>
      <div className={cn("container", styles.container)}>
        <Link className={styles.logo} to="/">
            <Image src="/images/logosol.png" />
        </Link>
        <div className={cn(styles.buttonWrapper)}>
            <Link
                className={cn(styles.profileButton)}
                to="/discover"
            >
                Discover
            </Link>
          <Link
            className={cn(styles.profileButton)}
            to="/profile"
          >
            Profile
          </Link>
            {
                wallet.account != null ?
                    (wallet.status === 'connected' ? (
                            <Link
                                className={cn("primary-active", styles.primaryButton)}
                            >
                                {wallet.account != null ? wallet.account.substring(0,4) + '...' + wallet.account.substring(39,42) : ''}
                            </Link>
                        ) : (
                            <Link
                                className={cn("primary-button", styles.primaryButton)}
                                onClick={() => wallet.connect()}>Connect</Link>
                        )
                    ) : <Link
                        className={cn("primary-button", styles.primaryButton)}
                        onClick={() => connectToBSCTestnet()}>Connect</Link>
            }
        </div>
      </div>
    </header>
  );
};

export default Headers;
