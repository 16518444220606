import React, { useState } from 'react'
import DerivativeCard from '../../components/DerivativeCard'
import NftCard from '../../components/NftCard'
import ShowMoreButton from '../../components/ShowMoreButton'
import { numberWithCommas } from '../../utils/format'
import infoSvg from '../../assets/images/info.svg'
import assetImg1 from '../../assets/images/asset01.png'
import assetImg2 from '../../assets/images/asset02.png'
import assetImg3 from '../../assets/images/asset03.png'
import assetImg4 from '../../assets/images/asset04.png'
import assetImg5 from '../../assets/images/asset05.png'
import assetImg6 from '../../assets/images/asset06.png'
import './style.scss'

const DerivativesLayout = () => {
  const [showMore, setShowMore] = useState(true)
  return (
    <div className="app-derivatives-layout">
      <div className="derivatives-detail">
        <div className="derivatives-layout-title">
          <p className="derivatives-title">Derivatives</p>
          <img src={infoSvg} className="info-icon" alt="info" />
        </div>
        <div className="derivatives-cards">
          <DerivativeCard label="Derivative earnings to date" value={numberWithCommas(1231)} unit="Avax" />
          <DerivativeCard label="Commission to NFT holder of this Original work" value={10} unit="%" />
        </div>
        {showMore && (
          <div className="derivatives-cards">
            <NftCard asset={assetImg1} category="Black Eyed Peas" title="Boom Boom Pow" value={322} />
            <NftCard asset={assetImg2} category="Kanye West" title="Stronger" value={210} />
            <NftCard asset={assetImg3} category="EXO" title="Overdose" value={188} />
            <NftCard asset={assetImg4} category="Vitamin Quartet" title="Harder, Better, Faster Stronger" value={50} />
            <NftCard asset={assetImg5} category="La Pompe Moderne" title="Plus Dur, Meilleur, Plus Rapide, Plus..." value={4} />
            <NftCard asset={assetImg6} category="Pete Heller" title="Harder, Better, Faster, Stronger (..." value={4} />
          </div>
        )}
        <div className="showmore-button">
          <ShowMoreButton show={showMore} onClick={() => setShowMore(!showMore)} />
        </div>
      </div>
    </div>
  )
}

export default DerivativesLayout
