import React, {useEffect, useState} from "react";
import { useWallet, UseWalletProvider } from 'use-wallet';
import cn from "classnames";
import Card from "./Card";
import FeesCard from "./Card/fees-index";
import styles from "./Collection.module.sass";
import {ethers} from "ethers";
import newExchangeAbi from "../../abis/newExchange.json";
import loading from "../../assets/loading.json";
import Lottie from "react-lottie";
import {Animated} from "react-animated-css";
import exchangeAbi from "../../abis/exchange.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Collection = ({ name }) => {
  const [menu, setMenu] = useState(0);
  const { account, provider, balance } = useWallet();
  let exchange;
  let buyExchange;
  const [nftList, setnftList] = useState([]);
  const [creationsList, setcreationsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoadingIndex, setButtonLoadingIndex] = useState(100000);
  let myNFTs = [];
  const wallet = useWallet();
  let dbList = [];


  useEffect(async () => {
    setLoading(true);
    let providerAvax = await new ethers.providers.JsonRpcProvider('https://api.avax-test.network/ext/bc/C/rpc');
    buyExchange = await new ethers.Contract('0x7E4BB05E084EB64D9cF55Fd4094688e20dCEeE7e', newExchangeAbi.abi, providerAvax);
    console.log('ACCOUNT: ' + account)
    let providerBsc = await new ethers.providers.JsonRpcProvider(
        "https://rpc.api.moonriver.moonbeam.network"
    );
    exchange = await new ethers.Contract(
        "0x25B928897C8cFAa4242d5476251d73FC3d1f0bb9",
        exchangeAbi.abi,
        providerBsc
    );

    try {
      const response = await exchange.getAllNFTs();
      for (let i = 0; i < response.length; i++) {
        dbList.push({
          "name": response[i][0],
          "price": response[i][1].toString(),
          "artistName": response[i][2],
          "imageUrl": response[i][3],
          "streamUrl": response[i].streamUrl,
          "amountAvailable": response[i].amountAvailable.toString(),
          "description": response[i][6],
          "id": response[i].id.toString(),
          "creator": response[i].creator,
          "feesAccrued": response[i].feesAccrued.toString(),
        })
      }
      console.log(dbList);
    } catch (e){
      console.log('ERROR');
    }

    let displayList = [];
    if (account != null) {
      try {
        const response = await buyExchange.getUserOwnedNFTList(account);
        for (let i = 0; i < response.length; i++) {
          console.log(parseInt(response[i]));
          displayList.push(dbList[parseInt(response[i])]);
        }
      } catch (e){
        console.log(e);
      }
    }
    console.log('display: ' + displayList);
    setnftList(displayList);
    setLoading(false);
  }, [wallet]);

  return (
      <div>
      <div className={styles.banner}>
        <div style={{
          width: '100%',
          minHeight: '20vh',
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 130px, #141414 194px) no-repeat, url('http://internal.releap.io/images/profilebg.png') top center no-repeat`,
          backgroundSize: '100%'
        }} />
        <div className={cn("container", styles.user)}>
          <span>My Collection</span>
        </div>
        <div className={styles.divider}>
          <div className={cn("container", styles.menuWrapper)}>
          <span className={cn(styles.menu, {
            [styles.active]: menu === 0,
          })}
                onClick={() => setMenu(0)}>
            My Collections ({nftList.length})
          </span>
            <span className={cn(styles.menu, {
              [styles.active]: menu === 1,
            })}
                  onClick={() => setMenu(1)}>
            My Creations ({creationsList.length})
          </span>
          </div>
        </div>
      </div>
      <div className={cn("container mt-5", styles.container)}>
        <div className="row">
          { loading ?  <div
              style={{
                display: 'flex',
                flexDirection: "row",
                alignContent: 'center',
                alignSelf: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
                paddingTop: 200
              }}><Lottie
              options={defaultOptions}
              height={100}
              width={100}
          /></div> : (
              menu == 2 ? <div>You do not have any favorites</div>  :
              (menu === 0 ?
              ( nftList.length > 0 ?
             nftList.map((item, index) => (
                 <Card item={item}
                       key={index}
                       loading={buttonLoadingIndex == index}
                       position={index}
                redeemRoyalty={() => null}/>
             )) : <div>You do not have any NFTs</div>)
              : (
                  creationsList.length > 0 ?
                  creationsList.map((item, index) => (
                      <Animated animationIn="fadeIn" animationInDuration={500} animationOutDuration={800} isVisible={true}>
                      <FeesCard
                          item={item}
                          key={index}
                          loading={buttonLoadingIndex == index}
                          redeemRoyalty={() => null}/>
                      </Animated>
                  )) : <div>You do not have any creations</div>))
            )
          }
        </div>
      </div>
    </div>
   );
};

export default Collection;
