import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import "./assets/css/sass/main.scss";
import Page from "./components/Page";
import Home from "./screens/Discover";
import Landing from "./screens/Landing";
import Collection from "./screens/Profile";
import Item from "./screens/Music";
import List from "./screens/Artist";
import ReactGA from 'react-ga';
import Event from './screens/Event';

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { useMemo } from "react";
import AppContext from "./contexts/AppContext";
import { useWallet, UseWalletProvider } from 'use-wallet';

function App() {
  const network = WalletAdapterNetwork.Devnet;

  const TRACKING_ID = "UA-215033582-1";

  ReactGA.initialize(TRACKING_ID);

  ReactGA.pageview(window.location.pathname);


  const nftList = [];

  return (
    <AppContext.Provider value={nftList}>
          <UseWalletProvider
              chainId={43113}
              connectors={{
                  portis: { dAppId: 'my-dapp-id-123-xyz' },
              }}
          >
              <WalletModalProvider logo="/Logo.png">
            <Router>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Page>
                      <Landing />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/discover"
                  render={() => (
                    <Page>
                      <Home />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/profile"
                  render={() => (
                    <Page>
                      <Collection />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/music/:id"
                  render={({ match, props }) => (
                    <Page>
                      <Item id={match.params.id} props={props} />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/artist/:name"
                  render={({ match }) => (
                    <Page>
                      <List name={match.params.name} />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/event/:id"
                  render={({ match, props }) => (
                    <Page>
                      <Event />
                    </Page>
                  )}
                />
              </Switch>
            </Router>
          </WalletModalProvider>
        </UseWalletProvider>
    </AppContext.Provider>
  );
}

export default App;
