import React from 'react'
import './style.scss'
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {Animated} from "react-animated-css";

const Album = (props) => {
  const loading = props.loading;
  return (
      loading ?
      <SkeletonTheme baseColor="#2e2e2e" highlightColor="#444">
        <Skeleton className="app-album-layout"/>
      </SkeletonTheme>: (
              <Animated animationIn="fadeIn" animationInDuration={200} animationOutDuration={400} isVisible={true}>

              <div className="app-album-layout">
    <img src={props.artist[0].imageUrl} className="album-img" alt="album"/>
  </div>
              </Animated>)
  )
}

export default Album
