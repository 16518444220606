import React, { useState } from 'react'
import PostPage from '../../layout/PostPage';
import './style.scss'
import Card from "../../screens/Discover/Card";
import 'react-loading-skeleton/dist/skeleton.css'

const ProfileTabsLayout = (props) => {
  const [currentTab, setCurrentTab] = useState('posts');
  const handleClickTab = e => () => {
    setCurrentTab(e)
  }
  const loading = props.loading;
  return (
    <div className="app-profile-tabs">
      <div className="profile-tabs-header-container">
        <div className="profile-tabs-header">
          <ul>
            <li>
              <div className={`profile-tabs-header-item ${currentTab === 'posts' ? ' active' : ''}`} onClick={handleClickTab('posts')}>
                <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.5 0H2.5C1.96957 0 1.46086 0.210714 1.08579 0.585786C0.710714 0.960859 0.5 1.46957 0.5 2L0.5 10C0.5 10.5304 0.710714 11.0391 1.08579 11.4142C1.46086 11.7893 1.96957 12 2.5 12H14.5C15.0304 12 15.5391 11.7893 15.9142 11.4142C16.2893 11.0391 16.5 10.5304 16.5 10V2C16.5 1.46957 16.2893 0.960859 15.9142 0.585786C15.5391 0.210714 15.0304 0 14.5 0ZM5.5 9.5H3C2.86739 9.5 2.74021 9.44732 2.64645 9.35355C2.55268 9.25979 2.5 9.13261 2.5 9V8.5C2.5 8.36739 2.55268 8.24021 2.64645 8.14645C2.74021 8.05268 2.86739 8 3 8H5.5V9.5ZM9.5 9C9.5 9.13261 9.44732 9.25979 9.35355 9.35355C9.25979 9.44732 9.13261 9.5 9 9.5H6.5V8H9C9.13261 8 9.25979 8.05268 9.35355 8.14645C9.44732 8.24021 9.5 8.36739 9.5 8.5V9ZM14.5 5C14.5 5.26522 14.3946 5.51957 14.2071 5.70711C14.0196 5.89464 13.7652 6 13.5 6H3.5C3.23478 6 2.98043 5.89464 2.79289 5.70711C2.60536 5.51957 2.5 5.26522 2.5 5V3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H13.5C13.7652 2 14.0196 2.10536 14.2071 2.29289C14.3946 2.48043 14.5 2.73478 14.5 3V5Z" fill="currentColor" />
                </svg>
                Circle
              </div>
            </li>
            <li>
              <div className={`profile-tabs-header-item ${currentTab === 'tracks' ? ' active' : ''}`} onClick={handleClickTab('tracks')}>
                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.1787 12H9.10728C8.85154 12 8.60627 11.8984 8.42543 11.7176C8.24459 11.5367 8.143 11.2915 8.143 11.0357V1.71429H5.57157V5.89286C5.57157 6.1486 5.46997 6.39387 5.28913 6.57471C5.1083 6.75555 4.86303 6.85714 4.60728 6.85714H0.857282C0.743618 6.85714 0.634609 6.81199 0.554237 6.73162C0.473864 6.65124 0.428711 6.54224 0.428711 6.42857V5.57143C0.428711 5.45776 0.473864 5.34876 0.554237 5.26838C0.634609 5.18801 0.743618 5.14286 0.857282 5.14286H3.85728V0.964286C3.85728 0.708541 3.95888 0.463271 4.13971 0.282433C4.32055 0.101594 4.56582 0 4.82157 0H8.893C9.14874 0 9.39401 0.101594 9.57485 0.282433C9.75569 0.463271 9.85728 0.708541 9.85728 0.964286V10.2857H12.4287V6.10714C12.4287 5.8514 12.5303 5.60613 12.7111 5.42529C12.892 5.24445 13.1373 5.14286 13.393 5.14286H17.143C17.2567 5.14286 17.3657 5.18801 17.446 5.26838C17.5264 5.34876 17.5716 5.45776 17.5716 5.57143V6.42857C17.5716 6.54224 17.5264 6.65124 17.446 6.73162C17.3657 6.81199 17.2567 6.85714 17.143 6.85714H14.143V11.0357C14.143 11.2915 14.0414 11.5367 13.8606 11.7176C13.6797 11.8984 13.4345 12 13.1787 12Z" fill="currentColor" />
                </svg>
                Tracks
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-tabs-body">
        {currentTab === 'posts' && <PostPage artist={props.artist} posts={props.posts} inCircle={props.inCircle} loading={loading}/>}
        {currentTab === 'tracks' &&
        <div className="row">
        {loading ? <div></div> : ( props.artist[0].map((item, index) => (
            <Card item={item} key={index} />
        )))}
        </div>
        }
      </div>
    </div>
  )
}

export default ProfileTabsLayout
