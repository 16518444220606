import React from 'react'
import './style.scss'

const Divider = () => (
  <div className="app-divider">
    <div className="divider-line" />
    <span className="divider-label">Or</span>
    <div className="divider-line" />
  </div>
)

export default Divider
