import React from 'react'
import Creator from '../Creator'
import Streaming from '../Streaming'
import Derivatives from '../Derivatives'
import History from '../History'
import NftDetails from '../NftDetails'
import './style.scss'

const DetailsLayout = (props) => (
  <div className="app-details-layout">
    <Creator artist={props.artist} loading={props.loading}/>
    <Streaming />
    <Derivatives />
    <History />
    <NftDetails />
  </div>
)

export default DetailsLayout
