import React from 'react'
import './style.scss'

const DerivativeCard = ({ label, unit, value }) => (
  <div className="app-derivative-card">
    <p className="derivative-card-label">{label}</p>
    <p className="derivative-card-value">{`${value}${unit}`}</p>
  </div>
)

export default DerivativeCard
