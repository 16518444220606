import React from 'react'
import postImg from '../../assets/images/post1.png'
import dotsSvg from '../../assets/images/dots.svg'
import keySvg from '../../assets/images/key.svg'
import heartSvg from '../../assets/images/heart.svg'
import postsSvg from '../../assets/images/posts.svg'
import commentSvg from '../../assets/images/comment.svg'
import shareSvg from '../../assets/images/share.svg'
import './style.scss'

const LockedPostCard = ({ data = {}, type = 'simple', artist = {} }) => {
  const { id, author, title, description, status, stats, comments } = data
  return (
      <div className={`app-post-card ${type}`} key={`post-card-${id}`}>
        <>
          <div className="post-header">
            <div className="post-author">
              <img src={data.imageUrl} className="avatar-img" alt="avatar" />
              <div className="post-author-detail">
                <p className="author-name">
                  <strong>{data.name}</strong>
                </p>
                <div className="post-title">CIRCLE EXCLUSIVE</div>
              </div>
            </div>
            <div className="dot-menu">
              <img src={dotsSvg} className="dots-icon" alt="dots" />
            </div>
          </div>
          <div className="post-body">
            <div className="post-image-container" style={{ backgroundImage: `url(${data.image})` }}>
              <img src={postImg} className="post-image" alt="post" />

              <div className="locked">
                <img src={keySvg} className="key-icon" alt="key" />
                <p className="lock-message">Unlock content by joining this circle</p>
              </div>

            </div>
            <div className="post-reviews">
              <div className="review-item">
                <img src={heartSvg} className="heart-icon" alt="heart" />
                <p className="review-value">-</p>
              </div>
              <div className="review-item">
                <img src={postsSvg} className="posts-icon" alt="posts" />
                <p className="review-value">-</p>
              </div>
              <div className="review-item">
                <img src={commentSvg} className="comment-icon" alt="comment" />
                <p className="review-value">-</p>
              </div>
              <div className="review-item">
                <img src={shareSvg} className="share-icon" alt="share" />
              </div>
            </div>
          </div>
          {/*{comments.map(comment => <CommentItem key={comment.id} data={comment} />)}*/}
          <div className="comment-input-box">
            <img src={commentSvg} className="comment-icon" alt="comment-icon" />
            <input placeholder="Write a comment..." className="comment-input" />
          </div>
        </>
      </div>
  )
}

export default LockedPostCard
