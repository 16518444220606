import React from 'react'
import './style.scss'

const Button = ({ enoughFunds, className, icon, title, type, size, onClick }) => (
  <div className={`${enoughFunds ? "app-button" : "app-button-no-money"} ${className} ${type} ${size}`} onClick={onClick}>
    {icon && <img src={icon} className="button-icon" alt="button-icon" />}
    {title}
  </div>
)

export default Button
