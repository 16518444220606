import React, { useEffect, useState } from "react";
import "../../screens/Event/style.scss";

const Event = () => {
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
      // Fakes a delay
      await timeout(1500);

      // Sets loading to false
      setLoading(false);
    }, []);

    //
    function timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

}

export default Event;
