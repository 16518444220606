import React from "react";
import badgeSvg from "../../assets/images/badge.svg";
import "./style.scss";
import { Link } from "react-router-dom";
import Waveform from "../../components/Waveform";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const MusicInfo = (props) => {
  const whiteListedId = [0, 2, 4, 5, 6, 7, 8, 9];
  const loading = props.loading;
  return (
    <div className="app-music-info">
      <div className="music-info-wrapper">
          {loading ? '' : <div className="badge-container">
          <Link
            to={{
              pathname: loading ? '' : `/artist/${props.artist[0].creator}`,
              state: { nft: loading ? '' : props.artist[1] },
            }}
          >
              {loading ? <Skeleton count={1}/> : <p className="badge-title">{props.artist[0].artistName}</p>}
          </Link>
          {!loading && whiteListedId.includes(parseInt(props.artist[0].id)) ? (
            <img src={badgeSvg} className="badge-icon" alt="badge-icon" />
          ) : (
            <div />
          )}
        </div>}
          {loading ?
          <div>
              <Skeleton width={100} count={1}/>
              <div style={{height: 10}}></div>
              <Skeleton height={20} width={300} count={1}/>
          </div> :
              <p className="music-title">{props.artist[0].name}</p>}
      </div>
        {loading ? '' :<Waveform onSeek={(seek) => props.onSeek(seek)} onPlayToggle={props.onPlayToggle} track={props.artist[0].id.toString()} volume={props.volume} playing={props.playing} tracktime={props.tracktime}/>}
    </div>
  );
};

export default MusicInfo;
