import React, {useEffect, useState} from "react";
import cn from "classnames";
import Filter from "./Filter";
import Card from "./Card";
import styles from "./Home.module.sass";
import { ethers } from "ethers";
import exchangeAbi from '../../abis/exchange.json';
import {Animated} from "react-animated-css";
import {toast} from "react-toastify";
import LoadingCard from ".//Card/loading-card"

const Home = () => {
    let provider;
    let exchange;

    const [nftList, setnftList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [price, setPrice] = useState(50);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10);
    const [tempList, setTempList] = useState([]);
    const [artistName, setArtistName] = useState('');
    const [selectedList, setSelectedList] = useState([]);
    const debouncedSearchTerm = useDebounce(artistName, 500);

    function useDebounce(value, delay) {
        // State and setters for debounced value
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(
            () => {
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);
                return () => {
                    clearTimeout(handler);
                };
            },
            [value, delay]
        );
        return debouncedValue;
    }

    useEffect(async () => {
        setLoading(true);
        provider = await new ethers.providers.JsonRpcProvider('https://rpc.api.moonriver.moonbeam.network');
        exchange = await new ethers.Contract('0x25B928897C8cFAa4242d5476251d73FC3d1f0bb9', exchangeAbi.abi, provider);
        try {
            const response = await exchange.getAllNFTs();
            let nftList = [];
            console.log(nftList)
            for (let i = 0; i < response.length; i++) {
                nftList.push({
                    "name": response[i][0],
                    "price": response[i][1].toString(),
                    "artistName": response[i][2],
                    "imageUrl": response[i][3],
                    "streamUrl": response[i].streamUrl,
                    "amountAvailable": response[i].amountAvailable.toString(),
                    "description": response[i][6],
                    "id": response[i].id.toString(),
                    "creator": response[i].creator,
                    "feesAccrued": response[i].feesAccrued.toString(),
                })
            }
            setTempList(nftList);
            setnftList(nftList);
            setLoading(false);
        } catch (e){
            console.log('ERROR');
            notifyError();
        }
    }, [])

    function setList(tag){
        if (tag == 'Rock'){
            setSelectedList([10,11,14,15,16,17]);
        } else if (tag == 'Electronic'){
            setSelectedList([3,5]);
        } else if (tag == 'Cantopop'){
            setSelectedList([2,6]);
        } else if (tag == 'Indie Pop'){
            setSelectedList([14]);
        } else if (tag == 'Rap'){
            setSelectedList([8,9]);
        } else if (tag == 'Korean'){
            setSelectedList([6, 19]);
        } else if (tag == 'Psychedelia'){
            setSelectedList([9]);
        } else if (tag == 'Hip Hop'){
            setSelectedList([0, 6, 8]);
        } else if (tag == 'Jazz'){
            setSelectedList([20]);
        } else {
            setSelectedList([]);
        }
    }

    const notifyError = () => toast.dark(
        'Error connecting to the Avax Testnet!', {
            position: "bottom-right",
            autoClose: 5000,
            style: {marginBottom: 70},
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    useEffect(async () => {
        let tempList = nftList;
        let maxPriceList = tempList.filter(nft => nft.price <= maxPrice * 1e18 && nft.price >= minPrice * 1e18);
        let filteredArtistList = maxPriceList;
        if (debouncedSearchTerm){
            setLoading(true);
            await new Promise(r => setTimeout(r, 500));
            filteredArtistList = maxPriceList.filter(nft => nft.artistName.toLowerCase().includes(artistName.toLowerCase()));
            setLoading(false);
        }
        if (selectedList.length > 0){
            console.log('selectedList', selectedList);
            let filteredlist = [];
            for (let i = 0; i < filteredArtistList.length; i++){
                if (selectedList.includes(parseInt(filteredArtistList[i].id))){
                    filteredlist.push(filteredArtistList[i]);
                }
            }
            filteredArtistList = filteredlist;
        }
        setTempList(filteredArtistList);
    }, [maxPrice, minPrice, selectedList, debouncedSearchTerm]);

    useEffect(() => {
        setMinPrice(0);
        setMaxPrice(price / 100);
    }, [price]);

  return (
    <div className={cn("container flex pt-4", styles.container)}>
        <div className={cn("w-28 pe-5", styles.filterContainer)}>
            <Animated animationIn="fadeIn" animationInDuration={800} animationOutDuration={800} isVisible={true}>
            <Filter selectedTag={(e) => setList(e)} maxPrice={(val) => setMaxPrice(val)} minPrice={(val) => setMinPrice(val)} artistName={(val) => setArtistName(val)} priceSlider={(val) => setPrice(val)}/>
            </Animated>
            </div>
        {loading ?
            <div className="w-72">
            <div className="row" style={{marginBottom: 50}}>
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
            </div>
            </div>
            :  <div className="w-72">

                <div className="row" style={{marginBottom: 50}}>
                { tempList.length > 0 ?
                    tempList.map((item, index) => (
                        <Card item={item} key={item.id} />
                    )) : <div className={cn("container flex pt-4", styles.container)}>No NFTs matching your query</div>
                }
            </div>
        </div>
        }
    </div>
  );
};

export default Home;
