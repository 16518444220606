import React, { useEffect, useState } from "react";
import "../../screens/Landing/style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore ,{ Navigation, Pagination, A11y, Autoplay } from "swiper";
import "swiper/swiper.scss"; // core Swiper
import "swiper/swiper-bundle.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import landingGraphicReep from "../../assets/images/landing-2-graphic.png";
import {Link} from "react-router-dom";

const Landing = () => {
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    // Fakes a delay
    await timeout(1500);

    // Sets loading to false
    setLoading(false);
  }, []);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  SwiperCore.use([Pagination, Autoplay]);

  const carouselCard = (title, description, buttonDesc, goTo, bgImage) => {
    return ( loading ? '' : <SwiperSlide>
      <div style={{
        "background-image": `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #141414 180%),
    url(${bgImage})`}}
           className="landing_hero_slide landing_hero_slide1">
        <div className="landing_hero_slide_container">
          <div className="mb-text">
            <h1 className="h1">{title}</h1>
            <div style={{marginTop: 10, maxWidth: 600}}>
              {description}
            </div>
          </div>
          <div>
            <Link to={goTo} className="btn btn-primary">
              <button className="btn-landing">{buttonDesc}</button>
            </Link>
          </div>
        </div>
      </div>
    </SwiperSlide>)
  }

  const statCard = (count, desc) => {
    return (loading ?
        <SwiperSlide className="landing_stats_card">
          <span className="number">
          <Skeleton count={1} width={100}/></span>
          <Skeleton count={1}/>
        </SwiperSlide>
        :
     <SwiperSlide className="landing_stats_card">
      <span className="number">{count}</span>
      <p>{desc}</p>
    </SwiperSlide>);
  }

  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className="landing">
        <div className="landing_hero">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{
              "clickable": true
            }}
            speed={1000}
            autoplay={{
              delay: 4000,
            }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {loading ? <div style={{
              backgroundColor: 'rgba(67, 67, 67, 0.1)'}}
               className="landing_hero_slide landing_hero_slide1">
              <div className="landing_hero_slide_container">
                <div className="mb-text">
                  <h1 className="h1"><Skeleton count={1} width={500}/></h1>
                  <div style={{marginTop: 10}}>
                    <Skeleton count={2} width={300}/>
                  </div>
                </div>
                <div>
                  <Skeleton count={1} width={150} height={40}/>
                </div>
              </div>
            </div> : ''}
            {carouselCard('Daniel Allen', 'Akin to the mysterious sea anemone, Daniel Allen’s sensibilities extend from deep underground straight up to the glimmering surface.', 'Explore Project', "/discover", 'https://pbs.twimg.com/profile_banners/2950230382/1633141340/1500x500')}
            {carouselCard('Tyson Yoshi', 'Former Greytone Music Hip Hop and R&B artist Tyson Yoshi\'s collection is now available ', 'Explore', '/discover', 'https://jet.my-magazine.me/public/upload/interviewArticle/original/f054c84038f4326756af45a2f2e99bd1ee0f53fe.jpg')}
          </Swiper>
        </div>
        <div className="landing_body reep-intro">
          <div className="reep-intro_desc">
            <img
              src={landingGraphicReep}
              style={{ height: "500px", marginRight: "-12px" }}
            />
            <div style={{ flexGrow: "4" }}>
              <div className="reep-intro_desc_text">
                {loading ? (
                  <Skeleton count={1} className="reep-intro_desc_text_h3" />
                ) : (
                  <h3 className="reep-intro_desc_text_h3">
                    Releap is powered by $REEP
                  </h3>
                )}
                {loading ? (
                  <Skeleton count={3} className="reep-intro_desc_text_p" />
                ) : (
                  <div className="reep-intro_desc_text_p">
                    <p>
                      REEP is the governance tokens of the Releap platform. Users
                      can earn a share of the fees generated by the platform through
                      staking on our platform. Furthermore, users can receive trading
                      fee discounts, exclusive features and services with the token.
                    </p>
                  </div>
                )}
              </div>
              {loading ? <Skeleton count={1} width={300}/> :
              <div className="reep-intro_desc_btn">
                <button className="btn-landing">Learn more</button>
                <button className="btn-landing">Buy $REEP</button>
              </div>}
            </div>
          </div>
          <div>
            <Swiper
              modules={[Navigation, Pagination, A11y, Autoplay]}
              spaceBetween={10}
              speed={1000}
              slidesPerView={5}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {statCard(26,'NFT listed')}
              {statCard(20,'Active creators')}
              {statCard('398,283','REEP Staked')}
              {statCard(298,'Active Users')}
              {statCard(3892,'Total transactions')}
            </Swiper>
          </div>
        </div>
        <div className="landing_body">
          <div>
            <h5 className="mb-text">Featured launches</h5>
          </div>
          {loading ? (
            <div className="landing_body_grid">
              <div className="feature-skeleton">
                <Skeleton count={2} height={20}/>
              </div>
              <div className="feature-skeleton">
                <Skeleton count={2} />
              </div>
              <div className="feature-skeleton">
                <Skeleton count={2} />
              </div>
              <div className="feature-skeleton">
                <Skeleton count={2} />
              </div>
              <div className="feature-skeleton">
                <Skeleton count={2} />
              </div>
              <div className="feature-skeleton">
                <Skeleton count={2} />
              </div>
            </div>
          ) : (
            <div className="landing_body_grid">
              <div className="grid_daniel-allen">
                <div className="feature_content">
                  <div className="mb-text">
                    <h4>Daniel Allen is back with another project!</h4>
                  </div>
                  <button className="btn-landing">See project</button>
                </div>
              </div>
              <div className="grid_curve-shingo">
                <div className="feature_content">
                  <div className="mb-text">
                    <h4>Meditative tracks by Curve Shingo</h4>
                    <p>
                      Mint on a collection of 100 tracks based on the backing
                      track by legendary Japanese beatmaker Curve Shingo.
                    </p>
                  </div>
                  <div className="feature_content_stats">
                    <div className="feature_content_stats_card">
                      <p>Items</p>
                      <div className="number">100</div>
                    </div>
                    <div className="feature_content_stats_card">
                      <p>Price</p>
                      <div className="number">2.5 Avax</div>
                    </div>
                    <div className="feature_content_stats_card">
                      <p>Time left</p>
                      <div className="number">23:02:12</div>
                    </div>
                  </div>
                  <button className="btn-landing">See project</button>
                </div>
              </div>

              <div className="grid_masterworks-bach">
                <div className="feature_content">
                  <div className="mb-text">
                    <h4>Masterworks by Bach: Live in Concert</h4>
                    <p>
                      Get a concert pass to the first series of virtual concerts
                      held by the Hong Kong Philharmonic Orchestra. VIP passes
                      start from 0.4 Avax.
                    </p>
                  </div>
                  <div className="feature_content_stats">
                    <div className="feature_content_stats_card">
                      <p>Items</p>
                      <div className="number">1,000</div>
                    </div>
                    <div className="feature_content_stats_card">
                      <p>Price</p>
                      <div className="number">0.4 Avax</div>
                    </div>
                    <div className="feature_content_stats_card">
                      <p>Time left</p>
                      <div className="number">23:02:12</div>
                    </div>
                  </div>
                  <button className="btn-landing">Get tickets</button>
                </div>
              </div>
              <div className="grid_wildstyle-wildebeats">
                <div className="feature_content">
                  <div className="mb-text">
                    <h4>WILD$TYLE Records presents: Wildebeats</h4>
                    <p>
                      Hong Kong rap collective WILD$TYLE Records releases 200
                      generative beats as NFTs.
                    </p>
                  </div>
                  <div className="feature_content_stats">
                    <div className="feature_content_stats_card">
                      <p>Items</p>
                      <div className="number">200</div>
                    </div>
                    <div className="feature_content_stats_card">
                      <p>Price</p>
                      <div className="number">0.4 Avax</div>
                    </div>
                    <div className="feature_content_stats_card">
                      <p>Starts on</p>
                      <div className="number">21 Nov 2021, 6:00 PM EST</div>
                    </div>
                  </div>
                  <button className="btn-landing">See project</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default Landing;
