import React, {useState} from "react";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import verified from "../../../assets/verified-1.png";
import solImage from "../../../assets/Avax.png";
import {Animated} from "react-animated-css";

const Card = (props) => {
  const item = props.item;
  const whiteListedId = [0, 2, 4, 5, 6, 7, 8, 9];
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(getRandomInt(50));
  const [repostCount, setRepostCount] = useState(getRandomInt(15));

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  return (
      <div className="col-lg-4 col-md-6">
        <div className={styles.container}>
          <div className={styles.cardContent}>
            <div className={styles.owner}>
              <Link
                  to={{ pathname: `/artist/${item.creator}`, state: { nft: props.item } }}
              >
                {item.artistName}{" "}
              </Link>
              {whiteListedId.includes(parseInt(item.id)) ? (
                  <img
                      style={{ height: 16, marginBottom: 2, paddingLeft: 5 }}
                      src={verified}
                  />
              ) : (
                  <div />
              )}
            </div>
            <div className={styles.imageWrapper}>
              <Link
                  className={styles.back}
                  to={{ pathname: `/music/${item.id}`, state: { nft: props.item } }}
              >
                <Animated animationIn="fadeIn" animationInDuration={500} animationOutDuration={800} isVisible={true}>
                <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${item.imageUrl})`,
                    }}
                >
                  <div className={styles.imageOverlay}>
                    <svg
                        className={styles.coverPlayBtn}
                        xmlns="http://www.w3.org/2000/svg"
                        width="62"
                        height="62"
                        viewBox="0 0 62 62"
                        fill="none"
                    >
                      <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0C13.8792 0 0 13.8792 0 31C0 48.1208 13.8792 62 31 62ZM25.5352 19.3207L42.7227 29.4818C44.2607 30.3852 44.2559 32.6117 42.7227 33.515L25.5352 43.6713C23.9873 44.5893 22 43.4857 22 41.6547V21.3373C22 19.2963 24.1387 18.4955 25.5352 19.3207Z"
                          fill="white"
                          fill-opacity="1"
                      />
                    </svg>
                  </div>
                </div>
                </Animated>
              </Link>
              <div className={styles.favorite}>
              </div>
            </div>
            <div className={styles.info}>
              <Link
                  className={styles.back}
                  to={{ pathname: `/music/${item.id}`, state: { nft: props.item } }}
              >
                <p className={styles.title}>{item.name}</p>
              </Link>
              {/* <div className="flex justify-end items-center"> */}
              <div className={styles.priceContainer}>
                <div className={styles.priceCaption}>Price</div>
                <div className={styles.price}>
                  <img
                      style={{ height: 18, paddingRight: 7, objectFit: "cover" }}
                      src={solImage}
                  />{" "}
                  {item.price / 1e18} AVAX
                </div>
              </div>
            </div>
          </div>
          <div className={styles.cardActions}>
            <div className={styles.cardActionsLeft}>
              <button className={styles.likeWrapper} onClick={() => {
                liked ? setLikeCount(likeCount - 1) : setLikeCount(likeCount + 1);
                liked ? setLiked(false) : setLiked(true);
              }}>
                <div className={styles.likeIconWrapper}>
                  <div className={styles.likeIconInteract}></div>
                  <svg
                      className={styles.likeIcon}
                      width="17"
                      height="13"
                      viewBox="0 0 17 13"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      fill-opacity="0.25"
                  >
                    <path d="M14.947 0.975316C13.2345 -0.358945 10.6877 -0.11895 9.11584 1.36388L8.50023 1.94387L7.88462 1.36388C6.3159 -0.11895 3.76595 -0.358945 2.05348 0.975316C0.0910163 2.50672 -0.0121068 5.25524 1.74411 6.91521L7.79087 12.6237C8.18149 12.9922 8.81585 12.9922 9.20647 12.6237L15.2532 6.91521C17.0126 5.25524 16.9094 2.50672 14.947 0.975316Z" />
                  </svg>
                </div>
                <span className={styles.likeCount}>{likeCount}</span>
              </button>
              {/* Repost */}
              <button className={styles.repostWrapper}>
                <div className={styles.repostIconWrapper}>
                  <div className={styles.repostIconInteract}></div>
                  <svg
                      className={styles.repostIcon}
                      width="19"
                      height="12"
                      viewBox="0 0 19 12"
                      fill="#585858"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.0141 9.10325C10.9547 9.03449 10.8723 9.00008 10.7669 9.00008H5.06673V5.39999H6.96662C7.13812 5.39999 7.28664 5.34059 7.4119 5.22182C7.53724 5.10309 7.5999 4.96248 7.5999 4.79988C7.5999 4.64998 7.5503 4.52175 7.45145 4.41556L4.28489 0.815507C4.15952 0.678119 3.9979 0.609327 3.79999 0.609327C3.602 0.609327 3.44045 0.678021 3.31515 0.815507L0.148521 4.41556C0.0493914 4.52175 0 4.64998 0 4.79988C0 4.96248 0.0627704 5.10309 0.187999 5.22182C0.313367 5.34059 0.461888 5.39999 0.633389 5.39999H2.53342V11.0251C2.53342 11.0439 2.53667 11.0799 2.54326 11.133C2.54988 11.1862 2.55986 11.2222 2.57303 11.2408C2.58614 11.2597 2.60433 11.2846 2.62742 11.3159C2.65036 11.3471 2.68014 11.3691 2.71632 11.3815C2.75261 11.3939 2.79722 11.4001 2.85004 11.4001H12.3501C12.4358 11.4001 12.51 11.3705 12.5726 11.3111C12.6353 11.2518 12.6666 11.1815 12.6666 11.1003C12.6666 11.0376 12.6436 10.9722 12.5974 10.9032L11.0141 9.10325Z" />
                    <path d="M18.812 6.7784C18.6867 6.65986 18.5382 6.6003 18.3667 6.6003H16.4667V0.975253C16.4667 0.956471 16.4634 0.920581 16.4568 0.86732C16.4501 0.814158 16.4402 0.778268 16.4271 0.759485C16.414 0.740736 16.3958 0.715682 16.3727 0.684487C16.3496 0.653325 16.32 0.631391 16.2836 0.618847C16.2473 0.606435 16.203 0.600098 16.15 0.600098H6.64998C6.56423 0.600098 6.49016 0.629815 6.42736 0.68915C6.36479 0.748551 6.3335 0.818886 6.3335 0.900058C6.3335 0.968785 6.35644 1.03127 6.40271 1.08755L7.98598 2.88758C8.04535 2.96258 8.12784 3.00008 8.23335 3.00008H13.9334V6.60006H12.0334C11.8619 6.60006 11.7135 6.6595 11.5882 6.77804C11.4628 6.89694 11.4 7.03774 11.4 7.20012C11.4 7.35011 11.4496 7.47811 11.5486 7.58453L14.7153 11.1845C14.8472 11.3283 15.0087 11.4001 15.2002 11.4001C15.3913 11.4001 15.5528 11.3283 15.6848 11.1845L18.8515 7.58453C18.9503 7.47811 19 7.35011 19 7.20012C19.0001 7.03784 18.9372 6.89717 18.812 6.7784Z" />
                  </svg>
                </div>
                <span className={styles.repostCount}>{repostCount}</span>
              </button>
            </div>
            <div className={styles.cardActionsRight}>
              {/* Share */}
              <button className={styles.shareWrapper}>
                <div className={styles.shareIconWrapper}>
                  <div className={styles.shareIconInteract}></div>
                  <svg
                      className={styles.shareIcon}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#585858"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M10.4057 5.24028L10.4538 5.1249L10.4057 5.24028C10.5088 5.28324 10.6194 5.30543 10.7311 5.30558C10.8428 5.30574 10.9535 5.28385 11.0567 5.24117C11.1599 5.19849 11.2537 5.13587 11.3327 5.05688C11.4117 4.97789 11.4743 4.88409 11.517 4.78086C11.5597 4.67763 11.5816 4.567 11.5814 4.45529C11.5813 4.34359 11.5591 4.23301 11.5161 4.1299C11.4732 4.02684 11.4104 3.93327 11.3312 3.85452C11.3312 3.85448 11.3311 3.85443 11.3311 3.85438L8.60044 1.12372C8.5216 1.04487 8.42799 0.982317 8.32496 0.939641C8.22194 0.896965 8.11151 0.875 8 0.875C7.88849 0.875 7.77806 0.896965 7.67504 0.939641C7.57201 0.982317 7.4784 1.04487 7.39955 1.12372C7.39955 1.12372 7.39955 1.12372 7.39955 1.12372L4.66892 3.85438C4.66888 3.85443 4.66884 3.85447 4.6688 3.85451C4.58965 3.93325 4.52682 4.02684 4.48388 4.1299C4.44092 4.23301 4.41873 4.34359 4.41858 4.45529C4.41843 4.567 4.44032 4.67763 4.483 4.78086C4.52567 4.8841 4.5883 4.97789 4.66729 5.05688C4.74627 5.13587 4.84007 5.19849 4.9433 5.24117C5.04653 5.28385 5.15717 5.30574 5.26887 5.30558C5.38058 5.30543 5.49115 5.28324 5.59427 5.24028C5.69733 5.19735 5.79092 5.13451 5.86967 5.05536C5.8697 5.05532 5.86974 5.05528 5.86978 5.05524L7.15086 3.77416V11.3793C7.15086 11.6045 7.24032 11.8205 7.39957 11.9798C7.55881 12.139 7.77479 12.2285 8 12.2285C8.22521 12.2285 8.44119 12.139 8.60043 11.9798C8.75967 11.8205 8.84914 11.6045 8.84914 11.3793V3.77416L10.1302 5.05524C10.1302 5.05527 10.1303 5.0553 10.1303 5.05533C10.2091 5.13449 10.3027 5.19734 10.4057 5.24028Z"
                        stroke-width="0.25"
                    />
                    <path
                        d="M14.2759 7.15088C14.0507 7.15088 13.8347 7.24034 13.6754 7.39959C13.5162 7.55883 13.4267 7.77481 13.4267 8.00002V13.4267H2.57328V8.00002C2.57328 7.77481 2.48381 7.55883 2.32457 7.39959C2.16532 7.24034 1.94934 7.15088 1.72414 7.15088C1.49893 7.15088 1.28295 7.24034 1.12371 7.39959C0.964463 7.55883 0.875 7.77481 0.875 8.00002V13.7931C0.875 14.1464 1.01532 14.4851 1.2651 14.7349C1.51488 14.9847 1.85366 15.125 2.2069 15.125H13.7931C14.1463 15.125 14.4851 14.9847 14.7349 14.7349C14.9847 14.4851 15.125 14.1464 15.125 13.7931V8.00002C15.125 7.77481 15.0355 7.55883 14.8763 7.39959C14.717 7.24034 14.5011 7.15088 14.2759 7.15088Z"
                        stroke-width="0.25"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <Link
              className={styles.back}
              to={{ pathname: `/music/${item.id}`, state: { nft: props.item } }}
          >
          </Link>
        </div>
      </div>
  );
};

export default Card;
