import React from 'react'
import { numberWithCommas } from '../../utils/format'
import arrowUpSvg from '../../assets/images/arrow-up-green.svg';
import arrowDownSvg from '../../assets/images/arrow-down-red.svg';
import './style.scss'

const MonthStatus = ({ months = 1, value, percent  = 0 }) => {
  const statusLabel = months > 1 ? `Past ${months} months` : `Past month`
  const statusValue = numberWithCommas(value)
  const prefix = percent === 0 ? '-' : percent > 0 ? '+' : ''
  const percentValue = percent === 0 ? '' : `${percent}%`
  const percentClass = percent === 0 ? '' : percent > 0 ? 'up' : 'down'
  return (
    <div className="app-month-status">
      <p className="status-label">{statusLabel}</p>
      <p className="status-value">{statusValue}</p>
      <div className="status-percent">
        <p className={`percent-value ${percentClass}`}>{`${prefix}${percentValue}`}</p>
        {percent > 0 && <img src={arrowUpSvg} className="arrow-icon" alt="arrow-up" />}
        {percent < 0 && <img src={arrowDownSvg} className="arrow-icon" alt="arrow-down" />}
      </div>
    </div>
  )
}

export default MonthStatus
