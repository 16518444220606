import React, {useEffect, useState} from 'react'
import Button from '../Button'
import peopleSvg from '../../assets/images/people.svg'
import stepSvg from '../../assets/images/step.svg'
import recPlusSvg from '../../assets/images/rec-plus.svg'
import './style.scss'
import {Link} from "react-router-dom";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CreatorCard = (props) => {

    const [followers, setFollowers] = useState(100);
    const [plays, setPlays] = useState(100);
    const [following, setFollowing] = useState(false);
    const loading = props.loading;

    useEffect(async () => {
        setFollowers(Math.floor(Math.random() * 100));
        setPlays(Math.floor(Math.random() * 100));
    }, [])

   return (
       <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className="app-creator-card">
        <p className="card-label">CREATOR</p>
       <Link to={{pathname: loading ? '' : `/artist/${props.artist[0].creator}`, state: {nft: loading ? '' : props.artist[1]}}} >
       <div className="card-avatar">
           { props.loading ? <Skeleton style={{borderRadius: 150,
               height: 120,
               width: 120,
               objectFit: 'cover'}} count={1} /> : <img src={props.artist[1].imageUrl} className="avatar-img" alt="avatar" style={{
                borderRadius: 150,
                height: 120,
                width: 120,
                objectFit: 'cover'}}/>}
        </div>
       </Link>
       {loading ? <Skeleton className="creator-name" count={1}></Skeleton> : <p className="creator-name">{props.artist[0].artistName}</p>}
        <div className="reviews-container">
            <div className="views-container">
                <img src={peopleSvg} className="people-icon" alt="people"/>
                <p className="review-number">{loading ? '-' : (following ? followers + 1 :followers)}</p>
            </div>
            <div className="views-container">
                <img src={stepSvg} className="step-icon" alt="step"/>
                <p className="review-number">{loading ? '-' : plays}</p>
            </div>
        </div>
        <Button onClick={() => setFollowing(!following)} icon={loading ? null : (following ? null : recPlusSvg)} title={loading ? '-' : (following ? "Following" : "Follow") } size="small" type="full"/>
    </div>
   </SkeletonTheme>);
}

export default CreatorCard
