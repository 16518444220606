import React, { useEffect, useState} from "react";
import WaveSurfer from "wavesurfer.js";
import { WaveformContainer, Wave, PlayButton } from "./Waveform.styled";
import "./style.scss";
import pauseIcon from "../../assets/images/playbackPause.svg";
import playIcon from "../../assets/images/playbackPlay.svg";

let waveform;
const Waveform = (props) => {

  const [playing, setPlaying] = useState(false);
  const url = `https://internal.releap.io/${props.track}.mp3`;

  useEffect(async () => {
    const track = document.querySelector("#track");

    waveform = WaveSurfer.create({
      barWidth: 3,
      barGap: 2,
      barRadius: 2,
      cursorWidth: 1,
      container: "#waveform",
      height: 128,
      barHeight: 1,
      progressColor: "rgba(255,255,255,0.8)",
      responsive: true,
      waveColor: "rgba(255,255,255,0.3)",
      cursorColor: "transparent",
      backend: "MediaElement",
    });

    waveform.load(track);
    waveform.setVolume(0);

    waveform.on('pause', function () {
      console.log('paused');
    });

    waveform.on('seek', function (seek) {
      props.onSeek(seek);
    });

    waveform.on('waveform-ready', function () {
    });
  }, []);

  useEffect(() => {
    if (props.playing){
      waveform.play();
    } else {
      waveform.pause();
    }
  }, [props.playing])

  function handlePlay(){
    setPlaying(!playing);
    props.onPlayToggle();
  }

  useEffect(() => {
    if (props.tracktime.currentTime != null && props.tracktime.currentTime != 0 && !props.tracktime.paused) {
      waveform.seekTo(props.tracktime.currentTime / props.tracktime.duration);
    }
  }, [props.tracktime])

    return (
      <WaveformContainer className="waveform-container">
        <PlayButton onClick={() => handlePlay()}>
          {props.playing ? <img className="playback-icon" src={pauseIcon}/> : <img className="playback-icon" src={playIcon}/> }
        </PlayButton>
        <Wave id="waveform" />
        <audio id="track" src={url} />
      </WaveformContainer>
    );
};

export default Waveform;
