import React from 'react'
import badgeSvg from '../../assets/images/check-badge.svg'
import './style.scss'

const NftCard = ({ asset, category, title, value }) => (
  <div className="app-nft-card">
    <img src={asset} className="nft-asset" alt="nft-asset" />
    <div className="nft-details">
      <div className="nft-header">
        <div className="nft-category">
          <p className="category-name">{category}</p>
          <img src={badgeSvg} className="badge-icon" alt="badge" />
        </div>
        <p className="nft-title">{title}</p>
      </div>
      <div className="nft-footer">
        <p className="price-label">price</p>
        <p className="price-value">{`${value} Avax`}</p>
      </div>
    </div>
  </div>
)

export default NftCard
