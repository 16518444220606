import React from 'react'
import dotsSvg from '../../assets/images/dots.svg'
import heartSvg from '../../assets/images/heart.svg'
import postsSvg from '../../assets/images/posts.svg'
import commentSvg from '../../assets/images/comment.svg'
import shareSvg from '../../assets/images/share.svg'
import './style.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PostCardLoading = ({ data = {}, type = 'simple', artist = {} }) => {
    return (
        <div className={`app-post-card ${type}`}>
            <>
                <div className="post-header">
                    <div className="post-author">
                        <Skeleton className="avatar-img"/>
                        <div className="post-author-detail">
                            <Skeleton className="author-name" count={1}/>
                            <Skeleton width={100} count={1}/>
                        </div>
                    </div>
                    <div className="dot-menu">
                        <img src={dotsSvg} className="dots-icon" alt="dots" />
                    </div>
                </div>
                <div className="post-body">
                    <Skeleton className="post-image-container" height={348} count={1}/>
                    <div className="post-reviews">
                        <div className="review-item">
                            <img src={heartSvg} className="heart-icon" alt="heart" />
                            <p className="review-value">-</p>
                        </div>
                        <div className="review-item">
                            <img src={postsSvg} className="posts-icon" alt="posts" />
                            <p className="review-value">-</p>
                        </div>
                        <div className="review-item">
                            <img src={commentSvg} className="comment-icon" alt="comment" />
                            <p className="review-value">-</p>
                        </div>
                        <div className="review-item">
                            <img src={shareSvg} className="share-icon" alt="share" />
                        </div>
                    </div>
                </div>
                <div className="comment-input-box">
                    <img src={commentSvg} className="comment-icon" alt="comment-icon" />
                    <input placeholder="Write a comment..." className="comment-input" />
                </div>
            </>
        </div>
    )
}

export default PostCardLoading
