import React from 'react'
import MonthStatus from '../../components/MonthStatus'
import TextLabel from '../../components/TextLabel'
import { numberWithCommas } from '../../utils/format'
import infoSvg from '../../assets/images/info.svg'
import lineChartSvg from '../../assets/images/line-chart.svg'
import './style.scss'
import ReactTooltip from "react-tooltip";

const StreamingLayout = () => (
  <div className="app-streaming-layout">
    <div className="streaming-status">
      <a data-tip="global">
      <div className="streaming-layout-title">
        <p className="streaming-title">Streaming Stats</p>
        <img src={infoSvg} className="info-icon" alt="info" />
        <ReactTooltip id='global' aria-haspopup='true' >
          <p>Streaming statistics are</p>
          <p>aggregated from Spotify API</p>
          <p>and YouTube API. We do not</p>
          <p>undertake to ensure the</p>
          <p>accuracy of the data</p>
          <p>provided by any third party</p>
          <p>platforms.</p>
        </ReactTooltip>
      </div>
      </a>
      <div className="streaming-cards">
        <MonthStatus months={12} value={ Math.floor(Math.random() * 1000000)} />
        <MonthStatus months={3} value={ Math.floor(Math.random() * 500000)} percent={3.20} />
        <MonthStatus months={1} value={ Math.floor(Math.random() * 500000)} percent={-5.10} />
      </div>
      <img src={lineChartSvg} className="line-chart" alt="line" />
      <div className="streaming-popular">
        <p className="popular-label">Popular In...</p>
        <div className="popular-card">
          <div className="popular-card half">
            <TextLabel label="Hong Kong" value="Hong Kong" type="country" />
            <TextLabel label={numberWithCommas(13192)} value="monthly listens" type="country"  align="right" />
          </div>
          <div className="popular-card half">
            <TextLabel label="China" value="China" type="country" />
            <TextLabel label={numberWithCommas(13192)} value="monthly listens" type="country" align="right" />
          </div>
          <div className="popular-card half">
            <TextLabel label="Japan" value="Japan" type="country" />
            <TextLabel label={numberWithCommas(7419)} value="monthly listens" type="country" align="right" />
          </div>
          <div className="popular-card half">
            <TextLabel label="Korea" value="Korea" type="country" />
            <TextLabel label={numberWithCommas(7419)} value="monthly listens" type="country" align="right" />
          </div>
          <div className="popular-card half">
            <TextLabel label="London" value="UK" type="country" />
            <TextLabel label={numberWithCommas(3643)} value="monthly listens" type="country" align="right" />
          </div>
          <div className="popular-card half">
            <TextLabel label="Sao Paulo" value="Brazil" type="country" />
            <TextLabel label={numberWithCommas(3643)} value="monthly listens" type="country" align="right" />
          </div>
          <div className="popular-card half">
            <TextLabel label="Los Angeles" value="US" type="country" />
            <TextLabel label={numberWithCommas(1939)} value="monthly listens" type="country" align="right" />
          </div>
          <div className="popular-card half">
            <TextLabel label="Stockholm" value="Sweden" type="country" />
            <TextLabel label={numberWithCommas(1939)} value="monthly listens" type="country" align="right" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default StreamingLayout
