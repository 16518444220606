import React, { useState } from 'react'
import TextLabel from '../../components/TextLabel'
import ShowMoreButton from '../../components/ShowMoreButton'
import './style.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const CreatorLayout = (props) => {
  const [showMore, setShowMore] = useState(true)
  const loading = props.loading;

    return (
    <div className="app-creator-layout">
      <div className="creator-details">
        {loading ? <Skeleton count={1} width={300} /> : <p className="description">{props.artist[0].description}</p>}
        {showMore && (
          <>
          <TextLabel loading={loading} label="Label" value="Greytone" />
          <TextLabel loading={loading} label="Producer(s)" value={props.artist == null ? '' : props.artist[0].artistName} />
          <TextLabel loading={loading} label="Songwriter(s)" value={props.artist == null ? '' : props.artist[0].artistName} />
          <TextLabel loading={loading} label="Released" value="13 October 2020" />
          <TextLabel loading={loading} label="Studio" value="Hong Kong" />
          </>
        )}
        <div className="showmore-button">
          <ShowMoreButton show={showMore} onClick={() => setShowMore(!showMore)} />
        </div>
      </div>
    </div>
  )
}

export default CreatorLayout
