import React from "react";
import Image from "../../../components/Image";
import styles from "./Card.module.sass";
import solimage from "../../../assets/Avax.png";
import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";

const Card = (props) => {
    const item = props.item;
    const number = (props.position + 3)
    return (
        <div className="col-lg-3 col-md-4 col-sm-6" style={{ backgroundColor: '#1A1A1A', paddingTop: 30, borderRadius: 10, marginLeft: 20, marginBottom: 20}}>
            <div className={styles.container}>
                <div className={styles.imageWrapper}>
                    <Link to={{pathname: `/music/${item.id}`}} >
                    <Image className={styles.image} src={item.imageUrl} />
                    </Link>
                </div>
                <p className={styles.title}>
                    {item.name + ' #' + number}
                </p>
                <div className="flex justify-between items-center">
                    <div className={styles.status}>
                        <p>Unclaimed Royalty</p>
                    </div>
                    <span className={styles.price}>
                        <img style={{height: 12, paddingRight: 10, objectFit: 'cover' }} src={solimage} />
                        Claimed
                    </span>
                </div>
                <div className={item.royalties > 0 ? styles.buttonContainer : styles.buttonInactive} style={{
                    backgroundColor: '#272727',
                    marginBottom: 0,
                    alignItems: 'end',
                justifyContent: 'end'}}>
                    {props.loading ? <button>
                    <Loader
                        type="ThreeDots"
                        color="#000000"
                        height={10}
                        width={30}
                    />
                    </button> :  (item.royalties > 0 ? <button onClick={() => props.redeemRoyalty()}>
                        Redeem Royalty
                    </button> : <button>
                        Redeemed
                    </button>)
                    }
                </div>
            </div>
        </div>
    );
};

export default Card;
