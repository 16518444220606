import React from 'react'
import LayoutInfo from '../../components/LayoutInfo'
import './style.scss'

const historyList = [
  { id: 1, from: 'cDA7...0B6f', to: '', content: 'listed their NFT', date: 'Thursday, 14 October 2021, 01:47:00' },
  { id: 2, from: 'cDA7...0B6f', to: '', content: 'listed their NFT', date: 'Sunday, 10 October 2021, 04:43:56' },
  { id: 3, from: 'cD06...72D7', to: '7hec...0B6f', content: 'transferred to', date: 'Sunday, 3 October 2021, 05:00:02' },
  { id: 4, from: 'cD06...72D7', to: '', content: 'minted this NFT', date: 'Sunday, 3 October 2021, 04:43:56' },
]

const HistoryLayout = () => (
  <div className="app-derivatives-layout">
    <LayoutInfo />
    <div className="derivatives-detail">
      <div className="derivatives-layout-title history">
        <p className="derivatives-title">History</p>
      </div>
      {historyList.map(item => (
        <div key={item.id} className="history-container">
          <p className="history-description">
            <strong>{`${item.from} `}</strong>
            {`${item.content} `}
            <strong>{item.to}</strong>
          </p>
          <p className="history-date">{item.date}</p>
        </div>
      ))}
    </div>
  </div>
)

export default HistoryLayout
