import React, { useState } from 'react'
import LayoutInfo from '../../components/LayoutInfo'
import ShowMoreButton from '../../components/ShowMoreButton'
import refreshSvg from '../../assets/images/refresh.svg'
import arrowSvg from '../../assets/images/arrow.svg'
import './style.scss'

const NFTDetailsLayout = () => {
  const [showMore, setShowMore] = useState(true)

  return (
    <div className="app-nft-layout">
      <LayoutInfo />
      <div className="nft-detail">
        <div className="nft-layout-title">
          <p className="nft-title">NFT Details</p>
          <div className="nft-refresh-button">
            <img src={refreshSvg} className="refresh-icon" alt="refresh" />
            <p className="refresh-button-text">Refresh metadata</p>
          </div>
        </div>
        <div className="nft-detail-list">
          <p className="label">Contract Address</p>
          <div className="value-group">
            <p className="value">3B3e...5405</p>
            <img src={arrowSvg} className="arrow-icon" alt="arrow" />
          </div>
        </div>
        {showMore && (
          <>
            <div className="nft-detail-list">
              <p className="label">Blockchain</p>
              <p className="value">Avax</p>
            </div>
            <div className="nft-detail-list">
              <p className="label">Arweave</p>
              <img src={arrowSvg} className="arrow-icon" alt="arrow" />
            </div>
            <div className="nft-detail-list">
              <p className="label">Arweave Metadata</p>
              <img src={arrowSvg} className="arrow-icon" alt="arrow" />
            </div>
          </>
        )}
        <div className="showmore-button">
          <ShowMoreButton show={showMore} onClick={() => setShowMore(!showMore)} />
        </div>
      </div>
    </div>
  )
}

export default NFTDetailsLayout
